import Table from '@tiptap/extension-table';

const CustomTable = Table.extend({
  addAttributes() {
    return {
      border: {
        default: 'visible',
        renderHTML: (attributes) => {
          return {
            'data-border': attributes.border,
          };
        },
        parseHTML: (element) => element.getAttribute('data-border'),
      },
    };
  },
});

export default CustomTable;
