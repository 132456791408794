import Color from '@tiptap/extension-color';
import Gapcursor from '@tiptap/extension-gapcursor';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import Youtube from '@tiptap/extension-youtube';
import Link from '@tiptap/extension-link';

import ResizableImageExtension from './extensions/resizable-image/ResizableImageExtension';
import CustomTable from './extensions/custom-table/CustomTable';
import IFrameExtension from './extensions/iframe/IFrameExtension';

export const defaultExtensions = [
  StarterKit,
  TextStyle,
  Color,
  Highlight,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Underline,
  Image.configure({
    allowBase64: true,
  }),
  Gapcursor,
  CustomTable.configure({
    resizable: true,
  }),
  TableRow,
  TableCell,
  TableHeader,
  ResizableImageExtension,
  Youtube.configure({
    modestBranding: true,
  }),
  IFrameExtension,
  Link.configure({
    HTMLAttributes: {
      target: '_blank',
    },
    openOnClick: false,
  }),
];

export const readModeExtensions = [
  StarterKit,
  TextStyle,
  Color,
  Highlight,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Underline,
  Image.configure({
    allowBase64: true,
  }),
  Gapcursor,
  CustomTable.configure({
    resizable: true,
  }),
  TableRow,
  TableCell,
  TableHeader,
  ResizableImageExtension,
  Youtube.configure({
    modestBranding: true,
  }),
  IFrameExtension,
  Link.configure({
    HTMLAttributes: {
      target: '_blank',
    },
    openOnClick: true,
  }),
];
