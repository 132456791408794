/* eslint-disable no-unused-vars */
// @ts-nocheck
import React, { useCallback, useRef, useState } from 'react';
import { BubbleMenu, Editor } from '@tiptap/react';
import {
  CallSplit,
  Delete,
  KeyboardArrowDown,
  Merge,
} from '@mui/icons-material';
import PropTypes from 'prop-types';

import {
  BubbleMenuButton,
  BubbleMenuContainer,
  ToolbarMenuItem,
  ToolbarMenu,
  AddIcon,
  DeleteIcon,
  ToolbarVerticalDivider,
} from '../components';

/**
 *
 * @param {object} props
 * @param {import('@tiptap/react').Editor} props.editor
 */
const shouldShow = ({ editor }) => {
  return editor.isActive('table');
};

const getOnClickHandler = (handler, close) => {
  return () => {
    handler();
    close();
  };
};

/**
 *
 * @param {object} props
 * @param {import('@tiptap/react').Editor} props.editor
 */
const TableBubbleMenu = ({ editor }) => {
  const [tableOptionsAnchorEl, setTableOptionsAnchorEl] = useState(null);
  const [cellOptionsAnchorEl, setCellOptionsAnchorEl] = useState(null);
  const tableOptionsRef = useRef();

  const addColumnBefore = () => editor.chain().focus().addColumnBefore().run();
  const addColumnAfter = () => editor.chain().focus().addColumnAfter().run();
  const addRowBefore = () => editor.chain().focus().addRowBefore().run();
  const addRowAfter = () => editor.chain().focus().addRowAfter().run();
  const deleteColumn = () => editor.chain().focus().deleteColumn().run();
  const deleteRow = () => editor.chain().focus().deleteRow().run();
  const deleteTable = () => editor.chain().focus().deleteTable().run();
  const mergeCells = () => editor.chain().focus().mergeCells().run();
  const splitCell = () => editor.chain().focus().splitCell().run();
  const toggleHeaderColumn = () =>
    editor.chain().focus().toggleHeaderColumn().run();
  const toggleHeaderRow = () => editor.chain().focus().toggleHeaderRow().run();
  const fixTables = () => editor.chain().focus().fixTables().run();

  const toggleTableOptionsMenu = useCallback(
    (e) => {
      setTableOptionsAnchorEl(tableOptionsAnchorEl ? null : e.currentTarget);
    },
    [tableOptionsAnchorEl, setTableOptionsAnchorEl]
  );

  const toggleCellOptionsMenu = useCallback(
    (e) => {
      setCellOptionsAnchorEl(cellOptionsAnchorEl ? null : e.currentTarget);
    },
    [cellOptionsAnchorEl, setCellOptionsAnchorEl]
  );

  const handleTableDelete = useCallback(() => deleteTable(), [deleteTable]);

  const handleToggleTableBorder = () => {
    editor.commands.updateAttributes('table', {
      border:
        editor.getAttributes('table').border === 'visible'
          ? 'hidden'
          : 'visible',
    });
  };

  const renderTableOptionsMenu = () => {
    return (
      <ToolbarMenu
        open={Boolean(tableOptionsAnchorEl)}
        anchorEl={tableOptionsAnchorEl}
        onClose={toggleTableOptionsMenu}
        anchorOrigin={{
          vertical: 40,
        }}
      >
        <ToolbarMenuItem
          onClick={getOnClickHandler(toggleHeaderRow, toggleTableOptionsMenu)}
        >
          Toggle Header Row
        </ToolbarMenuItem>
        <ToolbarMenuItem
          onClick={getOnClickHandler(
            toggleHeaderColumn,
            toggleTableOptionsMenu
          )}
        >
          Toggle Header Cell
        </ToolbarMenuItem>
        <ToolbarMenuItem
          onClick={getOnClickHandler(fixTables, toggleTableOptionsMenu)}
        >
          Fix tables
        </ToolbarMenuItem>
        <ToolbarMenuItem
          onClick={getOnClickHandler(
            handleToggleTableBorder,
            toggleTableOptionsMenu
          )}
        >
          {editor.getAttributes('table').border === 'visible' ? 'Hide' : 'Show'}{' '}
          borders
        </ToolbarMenuItem>
      </ToolbarMenu>
    );
  };

  const renderCellOptionsMenu = () => {
    return (
      <ToolbarMenu
        open={Boolean(cellOptionsAnchorEl)}
        anchorEl={cellOptionsAnchorEl}
        onClose={toggleCellOptionsMenu}
        anchorOrigin={{
          vertical: 40,
        }}
      >
        <ToolbarMenuItem
          onClick={getOnClickHandler(addColumnBefore, toggleCellOptionsMenu)}
        >
          <AddIcon /> Column before
        </ToolbarMenuItem>
        <ToolbarMenuItem
          onClick={getOnClickHandler(addColumnAfter, toggleCellOptionsMenu)}
        >
          <AddIcon /> Column after
        </ToolbarMenuItem>
        <ToolbarMenuItem
          onClick={getOnClickHandler(addRowBefore, toggleCellOptionsMenu)}
        >
          <AddIcon /> Row before
        </ToolbarMenuItem>
        <ToolbarMenuItem
          onClick={getOnClickHandler(addRowAfter, toggleCellOptionsMenu)}
        >
          <AddIcon /> Row after
        </ToolbarMenuItem>
        <ToolbarMenuItem
          onClick={getOnClickHandler(deleteColumn, toggleCellOptionsMenu)}
        >
          <DeleteIcon /> Delete column
        </ToolbarMenuItem>
        <ToolbarMenuItem
          onClick={getOnClickHandler(deleteRow, toggleCellOptionsMenu)}
        >
          <DeleteIcon /> Delete row
        </ToolbarMenuItem>
        <ToolbarMenuItem
          onClick={getOnClickHandler(splitCell, toggleCellOptionsMenu)}
        >
          <CallSplit sx={{ fontSize: 14 }} /> Split cell
        </ToolbarMenuItem>
        <ToolbarMenuItem
          onClick={getOnClickHandler(mergeCells, toggleCellOptionsMenu)}
        >
          <Merge sx={{ fontSize: 14 }} /> Merge cells
        </ToolbarMenuItem>
      </ToolbarMenu>
    );
  };

  return (
    <BubbleMenu
      editor={editor}
      pluginKey="tableBubbleMenu"
      shouldShow={shouldShow}
      tippyOptions={{
        offset: [0, 20],
      }}
    >
      <BubbleMenuContainer>
        <BubbleMenuButton
          ref={tableOptionsRef}
          onClick={toggleTableOptionsMenu}
        >
          Table options <KeyboardArrowDown fontSize="small" />
        </BubbleMenuButton>
        <ToolbarVerticalDivider />
        <BubbleMenuButton onClick={toggleCellOptionsMenu}>
          Column options <KeyboardArrowDown fontSize="small" />
        </BubbleMenuButton>
        <ToolbarVerticalDivider />
        <BubbleMenuButton onClick={handleTableDelete}>
          <Delete sx={{ color: '#f00', fontSize: 16 }} />
        </BubbleMenuButton>
      </BubbleMenuContainer>
      {renderTableOptionsMenu()}
      {renderCellOptionsMenu()}
    </BubbleMenu>
  );
};
TableBubbleMenu.propTypes = {
  editor: PropTypes.instanceOf(Editor).isRequired,
};

export default TableBubbleMenu;
