import React, { useCallback } from 'react';
import {
  Add,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  Remove,
  WidthFull,
  WidthNormal,
} from '@mui/icons-material';
import { BubbleMenu, Editor } from '@tiptap/react';
import PropTypes from 'prop-types';

import {
  BubbleMenuButton,
  BubbleMenuContainer,
  ToolbarVerticalDivider,
} from '../components';

const nodeName = 'resizable-image';

/**
 * @typedef ResizableImageBubbleMenuProps
 * @property {import('@tiptap/react').Editor} editor
 */

const shouldShow = ({ editor }) => editor.isActive(nodeName);

/**
 * @param {ResizableImageBubbleMenuProps} props
 */
const ResizableImageBubbleMenu = ({ editor }) => {
  const handleWidthIncrease = useCallback(() => {
    const currentWidth =
      editor.getAttributes(nodeName).width === '100%'
        ? 300
        : editor.getAttributes(nodeName).width;
    editor.commands.updateAttributes(nodeName, {
      width: Number(currentWidth) + 50,
    });
  }, [editor]);
  const handleWidthDecrease = useCallback(() => {
    const currentWidth =
      editor.getAttributes(nodeName).width === '100%'
        ? 300
        : editor.getAttributes(nodeName).width;
    editor.commands.updateAttributes(nodeName, {
      width: Number(currentWidth) - 50,
    });
  }, [editor]);

  const setDefaultWidth = useCallback(() => {
    editor.commands.updateAttributes(nodeName, {
      width: 300,
    });
  }, [editor]);

  const setFullWidth = useCallback(() => {
    editor.commands.updateAttributes(nodeName, {
      width: '100%',
    });
  }, [editor]);

  const getImageAlignmentHandler = (alignment) => {
    return () => {
      editor.commands.updateAttributes(nodeName, {
        alignment,
      });
    };
  };

  return (
    <BubbleMenu
      editor={editor}
      pluginKey="resizableImageMenu"
      shouldShow={shouldShow}
      tippyOptions={{
        offset: [0, 20],
      }}
    >
      <BubbleMenuContainer>
        <BubbleMenuButton
          onClick={setDefaultWidth}
          sx={{
            gap: 0.5,
          }}
        >
          <WidthNormal fontSize="small" /> Normal
        </BubbleMenuButton>
        <ToolbarVerticalDivider />
        <BubbleMenuButton
          onClick={setFullWidth}
          sx={{
            gap: 0.5,
          }}
        >
          <WidthFull fontSize="small" /> Full width
        </BubbleMenuButton>
        <ToolbarVerticalDivider />
        <BubbleMenuButton onClick={handleWidthDecrease}>
          <Remove fontSize="small" />
        </BubbleMenuButton>
        <BubbleMenuButton onClick={handleWidthIncrease}>
          <Add fontSize="small" />
        </BubbleMenuButton>
        <ToolbarVerticalDivider />
        <BubbleMenuButton onClick={getImageAlignmentHandler('start')}>
          <FormatAlignLeft fontSize="small" />
        </BubbleMenuButton>
        <BubbleMenuButton onClick={getImageAlignmentHandler('center')}>
          <FormatAlignCenter fontSize="small" />
        </BubbleMenuButton>
        <BubbleMenuButton onClick={getImageAlignmentHandler('end')}>
          <FormatAlignRight fontSize="small" />
        </BubbleMenuButton>
      </BubbleMenuContainer>
    </BubbleMenu>
  );
};
ResizableImageBubbleMenu.propTypes = {
  editor: PropTypes.instanceOf(Editor).isRequired,
};

export default ResizableImageBubbleMenu;
