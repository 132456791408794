// @ts-nocheck
/**
 * @file Entry point for Custom Editor created using TipTap
 *
 */

import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { Stack } from '@mui/material';
import { EditorContent, useEditor } from '@tiptap/react';
import PropTypes from 'prop-types';
import './tiptap.css';

import SolyticsEditorToolbar from './SolyticsEditorToolbar';
import { defaultExtensions, readModeExtensions } from '.';
import {
  SolyticsEditorProvider,
  useSolyticsEditorContext,
} from './context/SolyticsEditorContext';

/**
 * @typedef SolyticsEditorProps
 * @property {string | JSON} content
 * @property {boolean} editable
 * @property {string[]} [hiddenToolbarItems = []]
 * @property {import('@tiptap/react').UseEditorOptions} [useEditorOptions = {}]
 * @property {import('@tiptap/react').Extensions} [extraExtensions = []]
 */

/**
 * @typedef SolyticsEditorHandle
 * @property {() => string} getHTML
 */

const ReadOnlyEditor = ({ content }) => {
  const { editorRef, useEditorOptions, extraExtensions } =
    useSolyticsEditorContext();
  const editor = useEditor({
    content,
    extensions: [...readModeExtensions, ...extraExtensions],
    onCreate: ({ editor: e }) => {
      editorRef.current = e;
    },
    ...useEditorOptions,
  });

  return <EditorContent editor={editor} />;
};
ReadOnlyEditor.propTypes = {
  content: PropTypes.string.isRequired,
};

const EditableEditor = ({ content }) => {
  const { editorRef, useEditorOptions, extraExtensions } =
    useSolyticsEditorContext();
  const editor = useEditor({
    content,
    extensions: [...defaultExtensions, ...extraExtensions],
    onCreate: ({ editor: e }) => {
      editorRef.current = e;
    },
    ...useEditorOptions,
  });

  return (
    <>
      <SolyticsEditorToolbar editor={editor} />
      <EditorContent editor={editor} />
    </>
  );
};
EditableEditor.propTypes = {
  content: PropTypes.string.isRequired,
};

/**
 * @type {import('react').ForwardRefRenderFunction<SolyticsEditorHandle, SolyticsEditorProps & import('@mui/material').StackProps & import('react').RefAttributes>}
 */
const SolyticsEditor = forwardRef(
  (
    {
      content,
      editable,
      hiddenToolbarItems = [],
      useEditorOptions = {},
      extraExtensions = [],
      ...props
    },
    ref
  ) => {
    /**
     * @type {import('react').MutableRefObject<import('@tiptap/react').Editor>}
     */
    const editorRef = useRef(null);

    useImperativeHandle(ref, () => {
      return {
        getHTML: () => {
          if (editorRef.current) {
            return editorRef.current.getHTML();
          }
          return content;
        },
        setOptions: (options) => {
          if (editorRef.current) {
            editorRef.current.setOptions(options);
          }
        },
      };
    }, []);

    return (
      <SolyticsEditorProvider
        value={{
          editorRef,
          hiddenToolbarItems,
          useEditorOptions,
          extraExtensions,
        }}
      >
        <Stack gap={2} {...props}>
          {editable && <EditableEditor content={content} />}
          {!editable && <ReadOnlyEditor content={content} />}
        </Stack>
      </SolyticsEditorProvider>
    );
  }
);
SolyticsEditor.propTypes = {
  content: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  hiddenToolbarItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
  extraExtensions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  useEditorOptions: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default SolyticsEditor;
