// @ts-nocheck
import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

/**
 * @typedef IFrameModalProps
 * @property {'youtube' | 'iframe'} mode
 * @property {(url: string) => void} onSubmit
 */

const title = {
  youtube: 'Enter YouTube URL',
  iframe: 'Enter Embed URL',
};

/**
 * @param {IFrameModalProps & import("@mui/material").DialogProps} props
 */
const IFrameModal = ({ mode, onClose, onSubmit, ...props }) => {
  const [url, setURL] = useState('');
  const handleSubmit = useCallback(
    (e) => {
      onSubmit(url);
      onClose(e, 'escapeKeyDown');
      setURL('');
    },
    [onSubmit, onClose, url, setURL]
  );

  const handleClose = useCallback(
    (e) => {
      setURL('');
      onClose(e, 'escapeKeyDown');
    },
    [setURL, onClose]
  );

  const handleURLChange = useCallback(
    (e) => setURL(e?.target?.value),
    [setURL]
  );

  return (
    <Dialog onClose={handleClose} {...props}>
      <DialogTitle>{title[mode]}</DialogTitle>
      <DialogContent>
        <Stack padding={2}>
          <TextField
            label="URL"
            value={url}
            onChange={handleURLChange}
            required
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
IFrameModal.propTypes = {
  mode: PropTypes.oneOf(['youtube', 'iframe']).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default IFrameModal;
