// @ts-nocheck
import { useState } from 'react';
import axios from 'axios';
import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useTeam() {
  const [teamDetails, setTeamDetails] = useState([]);
  const getTeamDetails = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.TEAM_DETAILS}`,
        { params: body }
      )
      .then((res) => {
        setTeamDetails(res?.data?.Team);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const addSingleTeamMember = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.ADD_SINGLE_TEAM_MEMBER}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const addSubRoles = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.ADD_SUB_ROLES}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const removeTeamMember = (body) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.MI.REMOVE_TEAM_MEMBER}`,
        { data: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  return {
    teamDetails,
    setTeamDetails,
    getTeamDetails,
    addSingleTeamMember,
    removeTeamMember,
    addSubRoles,
  };
}
