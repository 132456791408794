import React from 'react';
import {
  Code,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  FormatUnderlined,
  Highlight,
  StrikethroughS,
  Terminal,
} from '@mui/icons-material';
import { Typography } from '@mui/material';

/**
 * @typedef SolyticsEditorToolbarItem
 * @property {'toggle' | 'divider' | 'heading'} type
 */

const toolbarItems = [
  {
    type: 'undo',
  },
  {
    type: 'redo',
  },
  {
    type: 'divider',
  },
  {
    type: 'heading',
  },
  {
    action: 'bold',
    buttonContent: <Typography>B</Typography>,
    type: 'toggle',
  },
  {
    action: 'italic',
    buttonContent: (
      <Typography>
        <em>I</em>
      </Typography>
    ),
    type: 'toggle',
  },
  {
    type: 'toggle',
    buttonContent: <FormatUnderlined sx={{ fontSize: 18 }} />,
    action: 'underline',
  },
  {
    type: 'toggle',
    action: 'strike',
    buttonContent: <StrikethroughS sx={{ fontSize: 18 }} />,
  },
  {
    type: 'toggle',
    action: 'highlight',
    buttonContent: <Highlight sx={{ fontSize: 18 }} />,
  },
  {
    type: 'divider',
  },
  {
    type: 'textAlign',
  },
  {
    type: 'divider',
  },
  {
    type: 'toggle',
    action: 'bulletList',
    buttonContent: <FormatListBulleted sx={{ fontSize: 18 }} />,
  },
  {
    type: 'toggle',
    action: 'orderedList',
    buttonContent: <FormatListNumbered sx={{ fontSize: 18 }} />,
  },
  {
    type: 'divider',
  },
  {
    type: 'toggle',
    action: 'code',
    buttonContent: <Code sx={{ fontSize: 18 }} />,
  },
  {
    type: 'toggle',
    action: 'codeBlock',
    buttonContent: <Terminal sx={{ fontSize: 18 }} />,
  },
  {
    type: 'toggle',
    action: 'blockquote',
    buttonContent: <FormatQuote sx={{ fontSize: 18 }} />,
  },
  {
    type: 'divider',
  },
  {
    type: 'link',
  },
  {
    type: 'image',
  },
  {
    type: 'table',
  },
  {
    type: 'youtube',
  },
  {
    type: 'iframe',
  },
];

export default toolbarItems;
