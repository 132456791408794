// @ts-nocheck
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { Box, Grid, Button, Typography, Slide } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { ReactMuiTableColumnHeaderTextEllipsis } from 'solytics-frontend';
import { useSelector } from 'react-redux';
import { withTransaction } from '@elastic/apm-rum-react';
import { useContextMenu } from 'react-contexify';
import { Helmet } from 'react-helmet';
import DeleteIcon from '@mui/icons-material/Delete';

import moment from 'moment';
import swalToast from '../../../components/Common/swalToast';
import useEmailTemplate from '../../../hooks/Configuration/useEmailTempate';
import CreateEditEmailTemplate from '../../../components/Configuration/EmailTemplate/CreateEditEmailTemplate';
import theme from '../../../Theme/base';
import useCommon from '../../../hooks/useCommon';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import ReactMuiTableListView from '../../../components/Common/ReactMuiTableListView';
import useEntity from '../../../hooks/Configuration/useEntity';
import SearchTextField from '../../../components/Common/SearchTextField';
import MODULE_SUB_MODULE from '../../../const/moduleSubmodule';
import { NOT_AUTHORIZED_TO_ACCESS_MESSAGE } from '../../../const/CommonConst';
import { changeTablePageIndex } from '../../../utils/Utils';
import { ContextMenus } from '../../../components/Common/ContextMenus';
import DeleteModal from '../../../components/Common/Modals/DeleteModal';

const WARNING_TEXT =
  'This might impact existing models and related entities email .';

const columns = [
  {
    heading: 'ID',
    accessor: 'email_template_id',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
  {
    heading: 'EVENT NAME',
    accessor: 'event_name',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
  {
    heading: 'EVENT TYPE',
    accessor: 'event_type',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
  {
    heading: 'DESCRIPTION',
    accessor: 'email_template_description',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
  {
    heading: 'CREATED AT',
    id: 'created_at',
    accessor: (row) => moment(row?.created_at).format('MM/DD/YYYY, h:mm:ss a'),
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
];

const BreadcrumbsMenu = [
  { id: 1, name: 'Configuration', path: '/configuration' },
  { id: 2, name: 'EmailTemplate' },
];

const EmailTemplate = () => {
  const { customEntityList } = useEntity();
  const {
    createEmailTemplate,
    getEmailTemplate,
    updateEmailTemplate,
    removeEmailTemplate,
    getEmailTemplateTags,
  } = useEmailTemplate();

  const [entityList, setEntityList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [openCreateEntityModal, setOpenCreateEntityModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [activeTemplate, setActiveTemplate] = useState({});
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [isDeleteEmailTemplate, setIsDeleteEmailTemplate] = useState(false);
  const [selectedSummarySectionForDelete, setSelectedEmailTemplate] =
    useState('');

  // This ref is used to check if data of table is manipulated using external actions or internal table actions. It is going to be true when we change the table values from external actions like delete, update, etc.
  const tableStateUpdateRef = useRef(false);
  const { show: showRowLevelMenus } = useContextMenu();

  const { common, dispatch, setEmailTemplateListSearch } = useCommon();
  const { users } = useSelector((state) => state);
  const { permissions } = users;
  const permission =
    permissions?.[
      `${[MODULE_SUB_MODULE?.CNF.key]}_${
        MODULE_SUB_MODULE?.CNF?.subModules?.ET?.key
      }`
    ];

  const fetchEmailTemplate = useCallback(() => {
    getEmailTemplate().then((res) => {
      if (res) {
        setEntityList(res?.data);
      }
    });
  }, [getEmailTemplate, setEntityList]);

  const fetchTemplateList = useCallback(() => {
    customEntityList().then((res) => {
      if (res) {
        setTemplateList(res?.data);
      }
    });
  }, [customEntityList, setTemplateList]);

  const handleEditTemplate = useCallback(
    (template) => {
      setActiveTemplate(template);
      setOpenCreateEntityModal('Edit');
    },
    [setActiveTemplate, setOpenCreateEntityModal]
  );

  const onTableStateChange = useCallback(
    ({ pageIndex, data, pageSize, gotoPage }) => {
      if (tableStateUpdateRef.current) {
        changeTablePageIndex(pageIndex, data, pageSize, gotoPage);
      }
      // Set 'tableStateUpdateRef' to 'false' to indicate that the table state is changed by internal table actions.
      tableStateUpdateRef.current = false;
    },
    [tableStateUpdateRef, changeTablePageIndex]
  );

  const deleteSummarySectionHandler = useCallback(
    (issueId) => {
      setSelectedEmailTemplate(issueId);
      setIsDeleteEmailTemplate(true);
    },
    [setSelectedEmailTemplate, setIsDeleteEmailTemplate]
  );

  const closeDeleteEmailTemplate = useCallback(() => {
    setIsDeleteEmailTemplate(false);
  }, [setIsDeleteEmailTemplate]);

  const deleteConfirmEmailTemplate = useCallback(() => {
    removeEmailTemplate({ template_id: selectedSummarySectionForDelete }).then(
      (res) => {
        if (res) {
          fetchEmailTemplate();
          closeDeleteEmailTemplate();
          swalToast({
            icon: 'success',
            title: res?.data?.msg,
          });
        }
      }
    );
  }, [
    selectedSummarySectionForDelete,
    fetchEmailTemplate,
    closeDeleteEmailTemplate,
  ]);
  const menus = useMemo(() => {
    return [
      {
        label: 'Edit Email template.',
        id: 'edit_temp',
        icon: BorderColorIcon,
        onClick: ({ props: row }) => {
          handleEditTemplate(row?.original);
        },
      },
      {
        label: 'Delete Email Template',
        id: 'delete',
        icon: DeleteIcon,
        // isMenuDisabled: () => permission !== 'Manage',
        onClick: ({ props }) => {
          // eslint-disable-next-line react/prop-types
          deleteSummarySectionHandler(props?.original?.email_template_id);
        },
      },
    ];
  }, [handleEditTemplate]);
  /**
   * Handles the change event for template list search input element.
   * If the input value starts with a space character, prevents the default behavior.
   * Otherwise, dispatches an action to update the template list search.
   * @param {Event} e - The change event object.
   * @returns {void}
   */
  const handleInputChange = useCallback(
    (e) => {
      if (e?.target?.value.startsWith(' ')) {
        e?.preventDefault();
        return;
      }
      dispatch(setEmailTemplateListSearch(e?.target?.value));
    },
    [dispatch, setEmailTemplateListSearch]
  );

  const handleCreateTemplate = useCallback(() => {
    setOpenCreateEntityModal('Create');
  }, [setOpenCreateEntityModal]);

  const handleGetCellProps = useCallback(
    (cell) => {
      return {
        onContextMenu: (e) => {
          setSelectedRow(cell?.row);
          showRowLevelMenus({
            id: `model-inventory-table-menus`,
            event: e,
            props: cell?.row,
          });
        },
        style: {
          backgroundColor:
            cell?.row?.id === selectedRow?.id
              ? theme?.palette?.secondary?.main
              : '',
          position: 'relative',
        },
      };
    },
    [setSelectedRow, showRowLevelMenus, selectedRow, theme]
  );

  const handleOnMenuExit = useCallback(() => {
    setSelectedRow(null);
  }, [setSelectedRow]);

  const handleClose = useCallback(() => {
    setOpenCreateEntityModal('');
    setActiveTemplate({});
  }, [setOpenCreateEntityModal]);

  const renderModals = () => {
    return (
      <>
        {openCreateEntityModal ? (
          <CreateEditEmailTemplate
            open={Boolean(openCreateEntityModal)}
            handleClose={handleClose}
            mode={openCreateEntityModal}
            entityList={templateList}
            activeTemplate={activeTemplate}
            createEmailTemplate={createEmailTemplate}
            updateEmailTemplate={updateEmailTemplate}
            fetchEmailTemplate={fetchEmailTemplate}
            getEmailTemplateTags={getEmailTemplateTags}
          />
        ) : null}{' '}
        <DeleteModal
          open={isDeleteEmailTemplate}
          handleClose={closeDeleteEmailTemplate}
          deleteConfirm={deleteConfirmEmailTemplate}
          alertLabelText="Do you want to delete this Email Template?"
          warningText={WARNING_TEXT}
        />
      </>
    );
  };

  useEffect(() => {
    // // turn off the flag to reset the page for next operations like global filter, etc.
    setSkipPageReset(false);

    // Set 'tableStateUpdateRef' to 'false' to reset.
    tableStateUpdateRef.current = false;
  }, [entityList]);

  useEffect(() => {
    fetchEmailTemplate();
    fetchTemplateList();
  }, []);

  return (
    <Box>
      <Grid container rowGap={1}>
        <Helmet>
          <title> Email Template | MRM Vault</title>
        </Helmet>
        <Grid
          item
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'center', sm: 'inherit' }}
          flexWrap="wrap"
          xs={12}
          sx={{ background: (_theme) => _theme.palette.secondary.light1 }}
          mb={1}
        >
          <Box>
            <Breadcrumb BreadcrumbsMen={BreadcrumbsMenu} />
          </Box>
        </Grid>

        <Grid item container xs={12} spacing={2} pl={2} pr={2}>
          <Grid item xs={12} display="flex" gap={2}>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
              }}
            >
              <SearchTextField
                value={common?.emailTemplateListSearch}
                onChange={handleInputChange}
                placeholder="Search"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
              }}
            >
              <Button
                disabled={permission !== 'Manage'}
                title={
                  permission !== 'Manage'
                    ? NOT_AUTHORIZED_TO_ACCESS_MESSAGE
                    : 'Create email template.'
                }
                variant="contained"
                onClick={handleCreateTemplate}
              >
                CREATE EMAIL TEMPLATE
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {entityList?.length > 0 ? (
              <Slide direction="left" timeout={1000} mountOnEnter in={true}>
                <Box
                  width="100%"
                  sx={{ height: 'calc(100vh  - 275px)', overflow: 'auto' }}
                >
                  <ReactMuiTableListView
                    data={entityList?.length > 0 ? entityList : []}
                    columns={columns}
                    enableRowSelection={true}
                    pageCount={entityList?.length}
                    enablePagination={true}
                    initialPageSize={10}
                    rowsPerPageOptions={[5, 10, 15]}
                    initialGlobalFilter={common?.templateListSearch}
                    skipPageReset={skipPageReset}
                    onTableStateChange={onTableStateChange}
                    getCellProps={handleGetCellProps}
                    hiddenColumns={['Attribute']}
                    isAllOptionDisabled={true}
                  />
                  <ContextMenus
                    menuId="model-inventory-table-menus"
                    menus={menus}
                    onMenuExit={handleOnMenuExit}
                  />
                </Box>
              </Slide>
            ) : (
              <Box
                display="flex"
                sx={{ height: 'calc(100vh  - 300px)' }}
                flexGrow={1}
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography variant="subtitle1">
                  No data result found.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>

      {renderModals()}
    </Box>
  );
};

export default withTransaction('EmailTemplate', 'component')(EmailTemplate);
