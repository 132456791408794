// @ts-nocheck
import { Container } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { render } from '@react-email/render';
import Mention from '@tiptap/extension-mention';
import Placeholder from '@tiptap/extension-placeholder';

import PropTypes from 'prop-types';
import DefaultEmailTemplate from './DefaultEmailTemplate';
import SolyticsEditor from '../../SolyticsEditor/SolyticsEditor';
import renderMentionList from '../../SolyticsEditor/utils/renderMentionList';

const domParser = new DOMParser();

const EmailTemplateEditor = ({
  value,
  onChange,
  templateTags = [],
  padding = '32px 0px',
  placeholder = 'Write something',
}) => {
  const solyticsEditorRef = useRef();
  const extensions = [
    Mention.configure({
      renderHTML: ({ node, options }) => {
        return ['span', options.HTMLAttributes, `{{${node?.attrs?.id}}}`];
      },
      suggestion: {
        char: '@',
        items: ({ query, editor }) => {
          return editor?.options?.editorProps?.templateTags?.filter((tag) =>
            tag?.toLowerCase()?.includes(query?.toLowerCase())
          );
        },
        render: renderMentionList,
      },
    }),
    Placeholder.configure({
      placeholder,
    }),
  ];

  useEffect(() => {
    if (solyticsEditorRef.current) {
      solyticsEditorRef?.current?.setOptions({
        editorProps: {
          templateTags,
        },
      });
    }
  }, [templateTags]);

  return (
    <Container sx={{ padding }}>
      <SolyticsEditor
        ref={solyticsEditorRef}
        content={value}
        editable
        hiddenToolbarItems={['image', 'youtube', 'iframe', 'table', 'link']}
        useEditorOptions={{
          onTransaction: ({ editor: e }) => {
            const templateContent = e.getHTML();
            const editorDoc = domParser.parseFromString(
              templateContent,
              'text/html'
            );
            render(
              <DefaultEmailTemplate bodyHTML={editorDoc.body.innerHTML} />,
              {
                htmlToTextOptions: {
                  preserveNewlines: true,
                },
              }
            ).then((c) => {
              const doc = domParser.parseFromString(c, 'text/html');
              onChange(doc.body.innerHTML);
            });
          },
        }}
        extraExtensions={extensions}
      />
    </Container>
  );
};
EmailTemplateEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  templateTags: PropTypes.oneOfType([PropTypes.array]).isRequired,
  padding: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default EmailTemplateEditor;
