// @ts-nocheck
import React, { useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { object, string } from 'yup';
import { useFormik } from 'formik';

/**
 * @typedef LinkModalProps
 * @property {(url: string) => void} onSubmit
 * @property {string} [currentURL]
 */

const validationSchema = object({
  url: string(),
});

/**
 * @param {LinkModalProps & import("@mui/material").DialogProps} props
 */
const LinkModal = ({ open, onClose, onSubmit, currentURL = '', ...props }) => {
  const handleSubmit = useCallback(
    (url) => {
      onSubmit(url);
      onClose();
    },
    [onSubmit, onClose]
  );

  const formik = useFormik({
    initialValues: {
      url: currentURL,
    },
    onSubmit: (values) => {
      handleSubmit(values.url);
    },
    enableReinitialize: true,
    validationSchema,
  });

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog open={open} onClose={handleClose} {...props}>
      <DialogTitle>URL</DialogTitle>
      <DialogContent>
        <Stack padding={2}>
          <TextField
            {...formik.getFieldProps('url')}
            name="url"
            label="URL"
            value={formik.values.url}
            error={Boolean(formik.touched.url && formik.errors.url)}
            helperText={formik.touched.url && formik.errors.url}
            required
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={formik.handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
LinkModal.propTypes = {
  open: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['youtube', 'iframe']).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentURL: PropTypes.string,
};
LinkModal.defaultProps = {
  currentURL: '',
};

export default LinkModal;
