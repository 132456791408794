// @ts-nocheck
import React, { useCallback } from 'react';
import {
  ButtonBase,
  styled,
  alpha,
  TextField,
  Stack,
  MenuItem,
  Menu,
  Paper,
  Divider,
} from '@mui/material';
import { Editor } from '@tiptap/react';
import PropTypes from 'prop-types';
import { Add, Delete } from '@mui/icons-material';

const boxShadow = 'var(--solytics-editor-box-shadow)';

export const ToolbarBaseButton = styled(ButtonBase, {
  name: 'SolyticsEditorToolbar',
  slot: 'baseButton',
})(({ theme }) => ({
  width: 30,
  height: 30,
  minWidth: 0,
  borderRadius: 4,
  border: 'none',
  color: '#000',
  backgroundColor: 'transparent',
  ':hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  display: 'grid',
  placeContent: 'center',
  '&.Mui-disabled': {
    color: 'rgba(0,0,0,0.2)',
  },
}));

export const ToggleButtonRoot = styled(ToolbarBaseButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
  name: 'SolyticsEditorToolbar',
  slot: 'toggleButton',
})(({ theme, isActive }) => ({
  width: 30,
  height: 30,
  minWidth: 0,
  borderRadius: 4,
  border: 'none',
  backgroundColor: isActive ? alpha(theme.palette.primary.main, 0.2) : '#fff',
  color: isActive ? '#000' : '#111',
  '.MuiTypography-root': {
    color: isActive ? '#000' : '#111',
  },
  display: 'grid',
  placeContent: 'center',
  ':hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
}));

/**
 * @param {import('@mui/material').TextFieldProps} props
 */
const SelectTextField = (props) => <TextField {...props} select />;

export const ToolbarDropdown = styled(SelectTextField, {
  name: 'SolyticsEditorToolbar',
  slot: 'headingDropdown',
})(({ theme }) => ({
  border: 'none',
  minWidth: 0,
  justifyContent: 'center',
  '.MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
  '.MuiSelect-select': {
    padding: '2px 8px',
    minWidth: 100,
    backgroundColor: 'transparent',
    fontWeight: 400,
  },
  '.MuiOutlinedInput-root': {
    height: '100%',
    ':hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    backgroundColor: 'transparent',
  },
  '.MuiSelect-icon': {
    right: '8px',
  },
}));

/**
 * @param {import('@mui/material').ButtonBaseProps} props
 * @param {import('@tiptap/react').Editor} props.editor
 * @param {import('./useSolyticsEditorToolbar').ToggleAction} props.action
 * @param {() => boolean} handleToggle
 */
export const ToggleButton = ({
  action,
  editor,
  handleToggle,
  children,
  ...props
}) => {
  const onClick = useCallback(
    () => handleToggle(action),
    [action, handleToggle]
  );
  return (
    <ToggleButtonRoot
      isActive={editor.isActive(action)}
      onClick={onClick}
      {...props}
    >
      {children}
    </ToggleButtonRoot>
  );
};
ToggleButton.propTypes = {
  action: PropTypes.string.isRequired,
  handleToggle: PropTypes.func.isRequired,
  editor: PropTypes.instanceOf(Editor).isRequired,
  children: PropTypes.node.isRequired,
};

export const BubbleMenuContainer = styled(Stack, {
  name: 'BubbleMenuContainer',
  slot: 'root',
})(({ theme }) => ({
  flexDirection: 'row',
  backgroundColor: '#ffffff',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  borderRadius: theme.spacing(1),
  boxShadow,
}));

export const BubbleMenuButton = styled(ButtonBase)(({ theme }) => ({
  padding: '2px',
  minWidth: 0,
  minHeight: 0,
  borderRadius: 4,
  border: 'none',
  color: '#000',
  backgroundColor: 'transparent',
  ':hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  textTransform: 'none',
  fontSize: 12,
}));

export const ToolbarPaper = styled(Paper)(() => ({
  boxShadow,
  transform: 'translateY(10px)',
}));

export const ToolbarMenu = styled(Menu)(() => ({
  '.MuiPaper-root': {
    boxShadow,
    transform: 'translateY(10px)',
  },
}));

export const ToolbarMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  ':hover': {
    fontWeight: 400,
  },
  gap: theme.spacing(1),
}));

export const AddIcon = () => <Add sx={{ fontSize: 14 }} />;
export const DeleteIcon = () => <Delete sx={{ fontSize: 14 }} />;

export const ToolbarVerticalDivider = () => (
  <Divider
    flexItem
    orientation="vertical"
    sx={{ backgroundColor: '#e7e4e2' }}
  />
);
