// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, Slide, Typography, useTheme } from '@mui/material';
import { ReactMuiTable } from 'solytics-frontend';
import { useContextMenu } from 'react-contexify';
import { isEmpty, debounce } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

import Breadcrumb from '../../components/Common/Breadcrumb';
import { ContextMenus } from '../../components/Common/ContextMenus';
import useModelInventory from '../../hooks/ModelInventory/useModelInventory';
import useCommon from '../../hooks/useCommon';
import SearchTextField from '../../components/Common/SearchTextField';
import swalToast from '../../components/Common/swalToast';

const INITIAL_PAGE_SIZE = 10;

const ArchivedEntity = () => {
  const theme = useTheme();

  const { getAllModelEntity, archiveEntity } = useModelInventory();
  const { common, dispatch, setModelArchivedSearch } = useCommon();
  const location = useLocation();
  const paths = location.pathname.split('/').slice(1);

  const isMyModel = paths?.includes('my-model');

  const [selectedRow, setSelectedRow] = useState(null);
  const [archivedEntityList, setArchivedEntityList] = useState([]);
  const [tableRowCount, setTableRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [skipReset, setSkipReset] = useState(true);
  const [currentPageSize, setCurrentPageSize] = useState(INITIAL_PAGE_SIZE);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);

  const history = useNavigate();

  const { show: showRowLevelMenus } = useContextMenu();

  const columns = useMemo(() => {
    return [
      {
        Header: 'UNIQUE ID',
        accessor: 'unique_id',
      },
      {
        Header: isMyModel ? 'MODEL NAME' : 'ARTIFACT Name',
        accessor: isMyModel ? 'model_name' : 'association_name',
      },
      {
        Header: 'WORKFLOW NAME',
        accessor: 'workflowName',
      },
    ];
  }, [isMyModel]);

  /**
   * Handles the change event for Model Inventory Search input element.
   * If the input value starts with a space character, prevents the default behavior.
   * Otherwise, dispatches an action to update the Model Inventory search.
   * @param {Event} e - The change event object.
   * @returns {void}
   */
  const handleInputChange = useCallback(
    (e) => {
      if (e?.target?.value.startsWith(' ')) {
        e?.preventDefault();
        return;
      }
      dispatch(setModelArchivedSearch(e?.target?.value));
    },
    [setModelArchivedSearch, dispatch]
  );

  /**
   * @function handleModelView
   * @description  to open respective Model
   * @param row- details of model
   */
  const handleModelView = (row) => {
    const basePath = isMyModel ? 'my-model' : 'model-artifact';
    const type = isMyModel ? 'ModelInventory' : 'ModelAssociation';

    history(
      {
        pathname: `/${basePath}/${row?.original?.unique_id}`,
      },
      {
        state: [
          {
            label: type,
            path: `/${basePath}`,
          },
          ...(isMyModel
            ? [
                {
                  label: 'Model Archive',
                  path: `/${basePath}/archived`,
                },
              ]
            : []),
          {
            label: row?.original?.unique_id,
            path: `/${basePath}/${row?.original?.unique_id}`,
            type,
          },
        ],
      }
    );
  };

  const handelChangeSkipReset = useCallback(
    (pageIndex) => {
      if (pageIndex === 1) {
        setSkipReset(false);
      } else {
        setSkipReset(true);
      }
    },
    [setSkipReset]
  );

  /**
   * @function getModelDetails
   * @description get Model Details according to condition
   * @param {text} -user search test
   * @param {globalSearch}- boolean global search is enabled or not
   * @param {_filterName} - if user selected any filter then its name
   * @param {pageSize} - size of page
   * @param {pageIndex} - page index of data
   * @param {filterType} - type of filter like previewSearch and savedSearch
   * @param {removeFilter} - it is used to decide remove filterType search params
   */

  const getModelDetails = useCallback(
    ({
      text = '',
      pageSize = currentPageSize,
      pageIndex = currentPageIndex,
      globalSearch = false,
      condition = null,
    }) => {
      handelChangeSkipReset(pageIndex);

      const checkGlobalSearch = () => {
        if (!isEmpty(common?.archivedModelSearch) || globalSearch) {
          return { globalSearch: true };
        }
        return null;
      };

      getAllModelEntity({
        entityType: isMyModel ? 'ModelInventory' : 'ModelAssociation',
        pageIndex,
        pageSize,
        ...(!isEmpty(text) ? { text } : null),
        ...checkGlobalSearch(),
        ...(!isEmpty(condition)
          ? { condition: JSON.stringify(condition) }
          : null),
        ...(!isEmpty(common?.archivedModelSearch)
          ? { text: common?.archivedModelSearch }
          : null),
        isArchived: true,
      }).then((res) => {
        setArchivedEntityList(res?.data);
        const countRows = res.headers.get('count');
        setArchivedEntityList(res?.data);
        setTableRowCount(countRows);

        setPageCount(Math.ceil(countRows / pageSize));
      });
    },
    [
      setPageCount,
      setTableRowCount,
      setArchivedEntityList,
      isEmpty,
      getAllModelEntity,
      setSkipReset,
      common?.archivedModelSearch,
    ]
  );

  const fetchData = () => {
    if (common?.archivedModelSearch?.trim().length > 0) {
      getModelDetails({
        text: common?.archivedModelSearch,
        globalSearch: true,
        pageIndex: 1,
        pageSize: INITIAL_PAGE_SIZE,
      });
    } else {
      getModelDetails({});
    }
  };
  const debouncedFetchData = debounce(fetchData, 500);

  useEffect(() => {
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [common?.archivedModelSearch]);

  const handleUnArchiveModel = useCallback(() => {
    const body = {
      entity_id: isMyModel
        ? selectedRow?.original?.model_id
        : selectedRow?.original?.association_id,
      entity_type: isMyModel ? 'ModelInventory' : 'ModelAssociation',
      is_archived: false,
    };
    archiveEntity(body).then((res) => {
      if (res?.data) {
        setSelectedRow(null);
        getModelDetails({
          pageIndex: 1,
          pageSize: currentPageSize,
        });
        swalToast({ icon: 'success', title: res?.data?.msg });
      }
    });
  }, [selectedRow, setSelectedRow, getModelDetails, swalToast, archiveEntity]);

  /**
   * @function menus
   * @description  array of different types of operation which will perform on specific model
   */
  const menus = useMemo(() => {
    return [
      {
        label: isMyModel ? 'View model details' : 'View artifact details',
        id: 'view_model_details',
        icon: VisibilityIcon,
        onClick: ({ props: row }) => {
          handleModelView(row);
        },
      },
      {
        label: 'UnArchive',
        id: 'unarchive_',
        icon: UnarchiveIcon,
        onClick: ({ props: row }) => {
          handleUnArchiveModel(row?.original);
        },
      },
    ];
  }, [handleModelView, selectedRow?.original, isMyModel]);

  const fetchDatasetRows = useCallback(
    (pageIndex, pageSize) => {
      setSkipReset(true);

      getAllModelEntity({
        entityType: isMyModel ? 'ModelInventory' : 'ModelAssociation',
        pageIndex: Number(pageIndex + 1),
        pageSize,
        ...(!isEmpty(common?.archivedModelSearch)
          ? { text: common?.archivedModelSearch }
          : null),
        ...(!isEmpty(common?.archivedModelSearch)
          ? { globalSearch: true }
          : null),
        isArchived: true,
      }).then((res) => {
        if (res) {
          const countRows = res.headers.get('count');
          setCurrentPageSize(pageSize);
          setCurrentPageIndex(pageIndex + 1);
          setArchivedEntityList(res?.data);
          setTableRowCount(countRows);
          setPageCount(Math.ceil(countRows / pageSize));
        }
      });
    },
    [
      setArchivedEntityList,
      setTableRowCount,
      setPageCount,
      common?.archivedModelSearch,
    ]
  );

  const handleGetRowProps = useCallback(
    (row) => {
      return {
        onContextMenu: (e) => {
          setSelectedRow(row);
          showRowLevelMenus({
            id: 'model-inventory-table-menus',
            event: e,
            props: row,
          });
        },
        style: {
          backgroundColor:
            row?.id === selectedRow?.id ? theme?.palette?.secondary?.main : '',
          cursor: 'pointer',
        },
      };
    },
    [selectedRow, showRowLevelMenus, setSelectedRow]
  );

  const handelGetHeaderProps = useCallback(() => {
    return {
      style: {
        display: 'flex',
        alignItems: 'center',
      },
    };
  }, []);

  const handleGetColumnProps = useCallback(() => {
    return {
      style: {
        textAlign: 'left',
        textOverflow: 'ellipsis',
        overflow: ' hidden',
        wordBreak: 'initial',
        whiteSpace: 'nowrap',
        paddingLeft: 10,
      },
    };
  }, []);

  const handelGetCellProps = useCallback(
    (cell) => {
      return {
        onContextMenu: (e) => {
          setSelectedRow(cell?.row);
          showRowLevelMenus({
            id: 'model-inventory-table-menus',
            event: e,
            props: cell?.row,
          });
        },
        style: {
          backgroundColor:
            cell?.row?.id === selectedRow?.id
              ? theme?.palette?.secondary?.main
              : '',
          position: 'relative',
        },
        onDoubleClick: () => {
          handleModelView(cell?.row);
        },
      };
    },
    [selectedRow, showRowLevelMenus, setSelectedRow]
  );

  const renderNoData = () => {
    return archivedEntityList?.length <= 0 ? (
      <Box
        display="flex"
        style={{ height: 'calc(100vh  - 500px)' }}
        flexGrow={1}
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant="subtitle1">No data result found.</Typography>
      </Box>
    ) : null;
  };

  const handleMenuExit = useCallback(() => {
    setSelectedRow(null);
  }, [setSelectedRow]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ background: (_theme) => _theme?.palette?.secondary?.light1 }}
      >
        <Box>
          <Breadcrumb />
        </Box>
      </Grid>
      <Grid item xs={12} p={2}>
        <SearchTextField
          value={common?.archivedModelSearch}
          onChange={handleInputChange}
          placeholder="Search"
        />
      </Grid>
      <Grid item xs={12} style={{ height: 'calc(100vh - 270px)' }} px={2}>
        <Box display="flex" flexGrow={1} flexDirection="column" overflow="auto">
          {archivedEntityList?.length > 0 ? (
            <Slide direction="left" timeout={1000} mountOnEnter in={true}>
              <Box>
                <ReactMuiTable
                  fetchData={fetchDatasetRows}
                  data={
                    archivedEntityList?.length > 0 ? archivedEntityList : []
                  }
                  columns={columns}
                  initialHiddenColumns={[]}
                  dataLength={tableRowCount}
                  enableControlledPagination={true}
                  getHeaderProps={handelGetHeaderProps}
                  getColumnProps={handleGetColumnProps}
                  getRowProps={handleGetRowProps}
                  stickyHeader={true}
                  stickyHeaderConfig={{
                    containerStyle: {
                      minHeight: 200,
                    },
                    tableHeadStyle: { zIndex: 1005 },
                  }}
                  enableRowSelection={false}
                  pageCount={pageCount}
                  enablePagination
                  initialPageSize={INITIAL_PAGE_SIZE}
                  rowsPerPageOptions={[5, 10, 15]}
                  isAllOptionDisabled={true}
                  showOptions={false}
                  showToolbar={false}
                  skipPageReset={skipReset}
                  getCellProps={handelGetCellProps}
                />
                <ContextMenus
                  menuId="model-inventory-table-menus"
                  menus={menus}
                  onMenuExit={handleMenuExit}
                />
              </Box>
            </Slide>
          ) : null}
          {renderNoData()}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ArchivedEntity;
