// @ts-nocheck
/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import { Input, Stack, Typography } from '@mui/material';

/**
 *
 * @param {import('@tiptap/react').NodeViewProps} props
 */
const ResizableImage = (props) => {
  const {
    node: {
      attrs: { width, caption, imageURL, alignment },
    },
    editor,
    updateAttributes,
  } = props;

  const handleCaptionChange = useCallback(
    (e) => {
      const update = e.target.value;
      updateAttributes({
        caption: update,
      });
    },
    [updateAttributes]
  );

  return (
    <NodeViewWrapper>
      <Stack>
        <Stack alignItems={alignment}>
          <div
            style={{
              width,
              maxWidth: '100%',
              height: 'fit-content',
            }}
          >
            <img style={{ objectFit: 'cover' }} src={imageURL} alt={caption} />
          </div>
        </Stack>
        <Stack
          width="100%"
          maxWidth={500}
          textAlign="center"
          alignSelf="center"
        >
          {editor.isEditable ? (
            <Input
              placeholder="caption"
              value={caption}
              onChange={handleCaptionChange}
              sx={{
                textAlign: 'center',
              }}
              slotProps={{
                input: {
                  sx: {
                    textAlign: 'center',
                  },
                },
              }}
            />
          ) : (
            <Typography>{caption}</Typography>
          )}
        </Stack>
      </Stack>
    </NodeViewWrapper>
  );
};

export default ResizableImage;
