import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import ResizableImage from './ResizableImage';

const nodeName = 'resizable-image';

const ResizableImageExtension = Node.create({
  name: nodeName,
  group: 'block',
  atom: true,
  addAttributes() {
    return {
      width: {
        default: '100%',
      },
      caption: {
        default: '',
      },
      imageURL: {
        default: '',
      },
      alignment: {
        default: 'center',
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: nodeName,
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [nodeName, mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(ResizableImage);
  },
});

export default ResizableImageExtension;
