// @ts-nocheck
import React from 'react';
import NotFound404 from './components/Common/NotFound404';
import { reactLazyRetry } from './utils/Utils';
import SuspenseComponent from './components/Common/SuspenseComponent';
import ErrorBoundary from './components/Common/ErrorBoundary';
import ArchivedEntity from './views/ModelInventory/ArchivedEntity';
import EmailTemplate from './views/Configuration/EmailTemplate/EmailTemplate';

const Layout = reactLazyRetry(() => import('./views/Layout/Layout'));
const Login = reactLazyRetry(() => import('./views/Login/Login'));
const Account = reactLazyRetry(() => import('./views/Login/Account'));
const Organization = reactLazyRetry(() => import('./views/Login/Organization'));

const HelpAndSupport = reactLazyRetry(
  () => import('./views/HelpAndSupport/HelpAndSupport')
);
const ThemeAppearance = reactLazyRetry(
  () => import('./views/Configuration/ThemeAppearance/ThemeAppearance')
);
const UserManagement = reactLazyRetry(
  () => import('./views/UserManagement/UserManagement')
);
const AppManagement = reactLazyRetry(
  () => import('./views/AppManagement/AppManagement')
);

const ModelInventory = reactLazyRetry(
  () => import('./views/ModelInventory/ModelInventory')
);
const ModelInventoryCreateModel = reactLazyRetry(
  () => import('./views/ModelInventory/CreateModelStepper')
);
const Dashboard = reactLazyRetry(
  () => import('./views/ModelInventory/Dashboard')
);
const BulkUpload = reactLazyRetry(
  () => import('./views/BulkActions/BulkUpload/BulkUpload')
);
const BulkTransition = reactLazyRetry(
  () => import('./views/BulkActions/BulkTransition/BulkTransition')
);
const BulkUpdate = reactLazyRetry(
  () => import('./views/BulkActions/BulkUpdate/BulkUpdate')
);
const ModelAssociation = reactLazyRetry(
  () => import('./views/ModelAssociation/ModelAssociation')
);
const DocumentInventory = reactLazyRetry(
  () => import('./views/DocumentInventory/DocumentInventory')
);
const EditModelAssociation = reactLazyRetry(
  () => import('./views/ModelAssociation/EditModelAssociation')
);
const ModelValidation = reactLazyRetry(
  () => import('./views/ModelAssociation/ModelValidation')
);
const NewTemplate = reactLazyRetry(
  () => import('./views/Configuration/CreateEditTemplate')
);
const TemplateList = reactLazyRetry(
  () => import('./views/Configuration/TemplateList')
);
const GlobalAttributes = reactLazyRetry(
  () => import('./views/Configuration/GlobalAttributes')
);
const EditModelDetails = reactLazyRetry(
  () => import('./views/ModelInventory/EditModelDetails')
);
const WorkFlowList = reactLazyRetry(
  () => import('./views/Configuration/Workflow/WorkFlowList')
);
const EditWorkFlow = reactLazyRetry(
  () => import('./views/Configuration/Workflow/EditWorkFlow')
);
const Rules = reactLazyRetry(() => import('./views/RulesAndReports/Rules'));

const Reports = reactLazyRetry(() => import('./views/RulesAndReports/Reports'));
const DocumentTemplate = reactLazyRetry(
  () => import('./views/Configuration/Documentation/DocumentTemplate')
);
const DynamicChart = reactLazyRetry(
  () => import('./views/Configuration/DynamicChart/DynamicChart')
);
const AutomationList = reactLazyRetry(
  () => import('./views/Configuration/Automation/AutomationList')
);
const CreateAutomation = reactLazyRetry(
  () => import('./views/Configuration/Automation/CreateAutomation')
);
const NotAuthorized = reactLazyRetry(
  () => import('./components/Common/NotAuthorized')
);

const ModelRequest = reactLazyRetry(
  () => import('./views/ModelInventory/ModelRequest/ModelRequest')
);
const EditModelRequest = reactLazyRetry(
  () => import('./views/ModelInventory/ModelRequest/EditModelRequest')
);
const AccessRequest = reactLazyRetry(
  () => import('./views/ModelInventory/AccessRequest/AccessRequest')
);

const EditAccessRequest = reactLazyRetry(
  () => import('./views/ModelInventory/AccessRequest/EditAccessRequest')
);

const UseCaseList = reactLazyRetry(
  () => import('./views/ModelInventory/UseCase/UseCaseList')
);
const EditUseCase = reactLazyRetry(
  () => import('./views/ModelInventory/UseCase/EditUseCase')
);

const Routes = [
  {
    name: 'Model Inventory',
    path: 'my-model',
    key: 'modelInventory',
    element: <ModelInventory />,
    permission: 'ModelInventory_modelInventory',
  },
  {
    name: 'Archived Models',
    path: 'my-model/archived',
    key: 'archivedModels',
    element: <ArchivedEntity />,
    permission: 'ModelInventory_modelInventory',
  },
  {
    name: 'Archived Artifacts',
    path: 'model-artifact/archived',
    key: 'archivedArtifacts',
    element: <ArchivedEntity />,
    permission: 'ModelAssociation',
  },
  {
    name: 'Create Model',
    path: 'my-model/create-model',
    key: 'createModel',
    element: <ModelInventoryCreateModel />,
    permission: 'ModelInventory_modelInventory',
  },
  {
    name: 'Edit Model ',
    key: 'editModel',
    path: 'my-model/:uniqueId',
    element: <EditModelDetails />,
  },

  {
    name: 'Dashboard',
    key: 'dashboard',
    path: 'dashboard',
    element: <Dashboard />,
    permission: 'ModelInventory_dashboard',
  },
  {
    name: 'Bulk Upload',
    key: 'bulkUpload',
    path: 'bulk-upload',
    element: <BulkUpload />,
    permission: 'BulkActions_bulkUpload',
  },
  {
    name: 'Bulk Transition',
    key: 'bulkTransition',
    path: 'bulk-transition',
    element: <BulkTransition />,
    permission: 'BulkActions_bulkTransition',
  },
  {
    name: 'Bulk Update',
    key: 'bulkUpdate',
    path: 'bulk-update',
    element: <BulkUpdate />,
    permission: 'BulkActions_bulkTransition',
  },
  {
    path: 'model-artifact',
    element: <ModelAssociation />,
    permission: 'ModelAssociation',
  },
  {
    path: 'model-artifact/:uniqueId',
    element: <EditModelAssociation />,
  },
  {
    path: 'model-validation',
    element: <ModelValidation />,
  },
  {
    path: 'templates/create-template',
    element: <NewTemplate />,
  },
  {
    path: 'templates/edit-template',
    element: <NewTemplate />,
  },
  {
    path: 'workflows',
    element: <WorkFlowList />,
    permission: 'Configuration_workflow',
  },
  {
    path: 'workflows/edit-workflow',
    element: <EditWorkFlow />,
  },
  {
    path: 'templates',
    element: <TemplateList />,
    permission: 'Configuration_templateList',
  },
  {
    path: 'global-attributes',
    element: <GlobalAttributes />,
    permission: 'Configuration_globalAttributes',
  },
  {
    path: 'rules',
    element: <Rules />,
    permission: 'RulesReports_rules',
  },
  {
    path: 'report',
    element: <Reports />,
    permission: 'RulesReports_report',
  },
  {
    path: 'user-management',
    element: <UserManagement />,
    permission: 'AdministrativeOptions_userManagement',
  },
  {
    path: 'app-management',
    element: <AppManagement />,
    permission: 'AdministrativeOptions_appManagement',
  },
  {
    name: 'Document Inventory',
    key: 'DocumentInventory',
    path: 'document-inventory',
    element: <DocumentInventory />,
    permission: 'DocumentInventory_documentInventory',
  },
  {
    name: 'Document Template',
    key: 'DocumentTemplate',
    path: 'document-template',
    element: <DocumentTemplate />,
    permission: 'Configuration_documentTemplate',
  },
  {
    name: 'Dynamic Chart',
    key: 'dynamicChart',
    path: 'dynamic-chart',
    element: <DynamicChart />,
    permission: 'Configuration_dynamicChart',
  },
  {
    name: 'Automation',
    key: 'automation',
    path: 'automation',
    element: <AutomationList />,
    permission: 'Configuration_automation',
  },
  {
    name: 'Theme and Appearance',
    key: 'themeAndAppearance',
    path: 'theme-appearance',
    element: <ThemeAppearance />,
    permission: 'Configuration_themeAndAppearance',
  },
  {
    name: 'Create Automation',
    key: 'createAutomation',
    path: 'automation/create-automation',
    element: <CreateAutomation />,
    permission: 'Configuration_automation',
  },
  {
    name: 'Update Automation',
    key: 'updateAutomation',
    path: 'automation/update-automation',
    element: <CreateAutomation />,
    permission: 'Configuration_automation',
  },
  {
    name: ' NotAuthorized',
    key: 'notAuthorized',
    path: 'not-authorized',
    element: <NotAuthorized />,
  },
  {
    name: 'Assessment',
    path: 'assessment',
    key: 'modelRequest',
    element: <ModelRequest />,
    permission: 'ModelInventory_modelRequest',
  },
  {
    name: 'Edit Assessment',
    key: 'editModelRequest',
    path: 'assessment/:uniqueId',
    element: <EditModelRequest />,
  },
  {
    name: 'Access Request',
    path: 'accessRequest',
    key: 'accessRequest',
    element: <AccessRequest />,
    // permission: 'ModelInventory_accessRequest',
  },
  {
    name: 'Access Request',
    key: 'editAccessRequest',
    path: 'accessRequest/:uniqueId',
    element: <EditAccessRequest />,
  },
  {
    name: 'UseCase',
    path: 'useCase',
    key: 'useCase',
    element: <UseCaseList />,
    permission: 'ModelInventory_useCase',
  },
  {
    name: 'Edit useCase',
    key: 'editUseCase',
    path: 'useCase/:uniqueId',
    element: <EditUseCase />,
  },
  {
    name: 'Email Template',
    key: 'EmailTemplate',
    path: 'email-template',
    element: <EmailTemplate />,
    permission: 'Configuration_emailTemplate',
  },
];

/**
 * @type {import('react-router-dom').RouteObject[]}
 * */
export const publicRoutes = [
  {
    path: '/login',
    element: <Login />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/login/:accountId',
    element: <Login />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/account',
    element: <Account />,
    errorElement: <ErrorBoundary />,
  },

  {
    path: '/org',
    element: <Organization />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '*',
    element: <NotFound404 />,
    errorElement: <ErrorBoundary />,
  },
];

/**
 * @param {Record<string, 'None' | 'View' | 'Manage'>} userPermissions User Permissions returned from API
 * @returns {import('react-router-dom').RouteObject[]}
 */
export const getProtectedRoutes = (userPermissions) => [
  {
    path: '/',
    element: <Layout />,
    children: Routes.map(({ path, element, permission }) => {
      const accessLevel = userPermissions?.[permission];
      if (accessLevel === 'None') {
        return {
          path,
          element: <NotAuthorized />,
        };
      }
      return {
        path,
        element: <SuspenseComponent>{element}</SuspenseComponent>,
        errorElement: <ErrorBoundary />,
      };
    }),
  },
  {
    path: '/help',
    element: (
      <SuspenseComponent>
        <HelpAndSupport />
      </SuspenseComponent>
    ),
    errorElement: <ErrorBoundary />,
  },
];
