/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
import { Body, Tailwind } from '@react-email/components';
import PropTypes from 'prop-types';
import React from 'react';

const DefaultEmailTemplate = ({ bodyHTML }) => {
  return (
    <Tailwind>
      <Body className="w-full font-sans whitespace-pre-wrap break-normal">
        <div dangerouslySetInnerHTML={{ __html: bodyHTML }} />
      </Body>
    </Tailwind>
  );
};
DefaultEmailTemplate.propTypes = {
  bodyHTML: PropTypes.string.isRequired,
};

export default DefaultEmailTemplate;
