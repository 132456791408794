// @ts-nocheck
import axios from 'axios';

import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useEmailTemplate() {
  const getEmailTemplate = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.EMAIL_TEMPLATE.GET_ALL_EMAIL_TEMPLATES}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const createEmailTemplate = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.EMAIL_TEMPLATE.CREATE_EMAIL_TEMPLATE}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const updateEmailTemplate = (body, params) => {
    return axios
      .put(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.EMAIL_TEMPLATE.UPDATE_EMAIL_TEMPLATE}`,
        body,
        { params }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const removeEmailTemplate = (params) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.EMAIL_TEMPLATE.UPDATE_EMAIL_TEMPLATE}`,
        { params }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const getEmailTemplateTags = (params) =>
    axios.get(
      `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.EMAIL_TEMPLATE.GET_EMAIL_TEMPLATE_TAGS}`,
      { params }
    );

  return {
    getEmailTemplate,
    updateEmailTemplate,
    createEmailTemplate,
    removeEmailTemplate,
    getEmailTemplateTags,
  };
}
