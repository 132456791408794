/* eslint-disable react/prop-types */
// @ts-nocheck
import React, { useCallback, useEffect, useId } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import { FixedSizeList } from 'react-window';
import { merge, cloneDeep } from 'lodash';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { Box, Checkbox, Typography, Pagination } from '@mui/material';

import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFlexLayout,
  useResizeColumns,
  useGroupBy,
  useExpanded,
  useColumnOrder,
} from 'react-table';

import SortButtonArrowIcon from '../../assets/images/SortButtonArrowIcon.svg';
import { ContextMenus } from './ContextMenus';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return <Checkbox sx={{ padding: 0 }} ref={resolvedRef} {...rest} />;
  }
);
IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool.isRequired,
};
const ReactMuiTablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handlePageChange = useCallback(
    (event, _page) => {
      onPageChange(event, _page - 1);
    },
    [onPageChange]
  );
  return (
    <Box sx={{ flexShrink: 0, marginLeft: (theme) => theme.spacing(2.5) }}>
      <Pagination
        count={
          count <= rowsPerPage && count / rowsPerPage === 0
            ? count / rowsPerPage
            : Math.max(0, Math.ceil(count / rowsPerPage))
        }
        page={page + 1}
        onChange={handlePageChange}
        color="primary"
        size="small"
        boundaryCount={3}
      />
    </Box>
  );
};

ReactMuiTablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const ROW_LEVEL_HOVER_OPTIONS_DEFAULT_STYLE = {
  position: 'absolute',
  float: 'right',
  right: 0,
  height: 'inherit',
  display: 'none',
};
const defaultPropGetter = () => ({});

const sortedButton = (column) => {
  return !column.disableSortBy ? (
    <Box
      display="flex"
      alignItems="center"
      role="img"
      component="button"
      aria-label="sort-column"
      sx={{
        border: 'none',
        background: 'transparent',
      }}
    >
      <img
        src={SortButtonArrowIcon}
        alt="SortButtonArrowIcon"
        height={11}
        width={11}
      />
    </Box>
  ) : null;
};
const ReactMuiTableListView = ({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  rowLevelOnHoverOptions,
  externalCustomTheme,
  rowSelectionConfig,
  onTableStateChange,
  enableRowSelection,
  toggle,
  enablePagination = false,
  isAllOptionDisabled = true,
  rowsPerPageOptions,
  // pageCount,
  pageCount: controlledPageCount,
  initialPageSize = 10,
  renderRowSubComponent,
  initialGlobalFilter,
  autoResetExpanded = true,
  noSearchResultMessage = 'No data result found.',
  skipPageReset,
  rowLevelRightClickMenus = [],
  enableControlledPagination = false,
  fetchData,
  dataLength,
  labelDisplayedRows,
  showPaginationAction = true,
  enableVirtualization = false,
  maxHeightForVirtualization = document.documentElement.clientHeight * 0.65,
  itemHeightForVirtualization = 40,
  hiddenColumns = [],
}) => {
  const theme = useTheme();
  const CUSTOM_THEME = {
    components: {
      MuiTableBody: {
        styleOverrides: {
          root: {
            borderLeft: 0,
            borderRight: 0,
            borderBottom: 0,
            borderTop: 0,
            borderStyle: 'solid',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.12)',
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          borderBottom: 0,
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.12)',
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '& .MuiSvgIcon-root': {
              fontSize: '20px',
            },
            // padding: 0,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            justifyContent: 'flex-start',
            borderBottom: 0,
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.main,
            fontWeight: 700,

            '& .MuiCheckbox-root': {
              color: theme.palette.primary.main,
            },
            '& .Mui-checked': {
              color: theme.palette.primary.main,
            },
          },
          root: {
            borderBottom: '1px solid ',
            minHeight: 10,
            background: theme.palette.other.white,
          },
          sizeMedium: {
            lineHeight: 1,
            padding: '14px !important',
            borderColor: theme.palette.other.gray4,
          },
        },
      },
    },
  };
  const newTheme = createTheme(
    merge(cloneDeep(theme), CUSTOM_THEME, externalCustomTheme)
  );
  // eslint-disable-next-line react/no-unstable-nested-components
  const CheckBoxCell = ({ row }) => {
    return (
      <div>
        <IndeterminateCheckbox
          {...row.getToggleRowSelectedProps({
            // eslint-disable-next-line no-use-before-define
            onChange: toggleRowSelection.bind(null, row),
          })}
        />
      </div>
    );
  };
  CheckBoxCell.propTypes = {
    row: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const RowSelectionHeader = ({ getToggleAllRowsSelectedProps }) => {
    return (
      <Box display="flex" alignItems="center" textAlign="center">
        {rowSelectionConfig.type === 'multi-select' ? (
          <>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            <Typography variant="body3" ml={1} color="inherit">
              Select all
            </Typography>
          </>
        ) : (
          <Typography
            variant="body3"
            sx={{ fontWeight: 700 }}
            ml={1}
            color="inherit"
          >
            SELECT
          </Typography>
        )}
      </Box>
    );
  };
  RowSelectionHeader.propTypes = {
    getToggleAllRowsSelectedProps: PropTypes.func.isRequired,
  };
  const menuId = useId();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    gotoPage,
    setPageSize,
    prepareRow,
    toggleAllRowsSelected,
    getToggleAllRowsSelectedHandler,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
  } = useTable(
    {
      columns,
      data,
      autoResetExpanded,
      autoResetSelectedRows: false,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        globalFilter: initialGlobalFilter,
        hiddenColumns,
      },
      pageCount: controlledPageCount,
      manualPagination: enableControlledPagination,

      autoResetPage: !skipPageReset,
    },
    useGlobalFilter,
    useFlexLayout,
    useResizeColumns,
    useColumnOrder,
    useGroupBy,

    useSortBy,
    useExpanded,
    usePagination,

    useRowSelect,

    (hooks) => {
      if (rowSelectionConfig.enable && rowSelectionConfig.mode === 'checkbox') {
        // eslint-disable-next-line no-shadow
        hooks.allColumns.push((_columns) => [
          {
            id: 'selection',
            Header: RowSelectionHeader,
            width: 100,
            Cell: CheckBoxCell,
          },
          ..._columns,
        ]);
      }
    }
  );

  // globalFilter effect should only be considered when controlled filtering is true else not.
  React.useEffect(() => {
    if (enableControlledPagination && fetchData) {
      fetchData(pageIndex, pageSize);
    }
  }, [enableControlledPagination, pageIndex, pageSize]);

  React.useEffect(() => {
    if (onTableStateChange) {
      onTableStateChange({
        pageIndex,
        selectedRowIds,
        getToggleAllRowsSelectedHandler,
        toggleAllRowsSelected,
        globalFilter,
        data,
        pageSize,
        gotoPage,
      });
    }
  }, [selectedRowIds, pageIndex, pageSize, globalFilter, data, gotoPage]);
  const toggleRow = () => {
    toggleAllRowsSelected(false);
  };
  useEffect(() => {
    setGlobalFilter(initialGlobalFilter);
  }, [initialGlobalFilter]);
  useEffect(() => {
    toggleRow();
  }, [toggle]);

  const allOptionValueSetter = (rowsPerPageOptionsParam, dataLengthParam) => {
    if (
      rowsPerPageOptionsParam &&
      [...rowsPerPageOptionsParam, 5, 10, 20, 50].includes(
        Number(dataLengthParam)
      )
    ) {
      return Number(dataLengthParam) + 1;
    }
    if ([5, 10, 20, 50].includes(Number(dataLengthParam))) {
      return Number(dataLengthParam) + 1;
    }
    return dataLengthParam;
  };

  const toggleRowSelection = React.useCallback(
    (row) => {
      if (rowSelectionConfig.enable) {
        if (rowSelectionConfig.type === 'single-select' && !row.isSelected) {
          toggleAllRowsSelected(false);
        }
        row.toggleRowSelected();
      }
    },
    [rowSelectionConfig, toggleAllRowsSelected]
  );

  const renderCell = (cell) => {
    // If the cell is aggregated, use the Aggregated
    // renderer for cell
    if (cell.isAggregated) {
      return cell.render('Aggregated');
    }
    if (cell.isPlaceholder) {
      return null; // For cells with repeated values, render null
    }
    return cell.render('Cell'); // Otherwise, just render the regular cell
  };

  // Virtualized rows Row Render function
  const VirtualizedRowsRenderer = React.useCallback(
    ({ index, style }) => {
      const row = (enablePagination ? page : rows)[index];
      prepareRow(row);
      return (
        <TableRow
          role="row"
          tabIndex={0}
          {...row.getRowProps([
            {
              style: {
                ...style,
                ...(rowSelectionConfig.enable &&
                rowSelectionConfig.selectedRowStyle &&
                Object.keys(selectedRowIds).includes(row.id)
                  ? rowSelectionConfig.selectedRowStyle
                  : {}),
              },
              ...(rowSelectionConfig.enable &&
              rowSelectionConfig.mode === 'row-click'
                ? { onClick: toggleRowSelection.bind(null, row) }
                : {}),
            },
            getRowProps(row, toggleAllRowsSelected),
          ])}
          sx={{
            '&:focus-visible': {
              border: (_theme) =>
                `2px solid ${_theme?.palette?.primary?.light}`,
            },
          }}
        >
          {row.cells.map((cell) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <TableCell
                role="cell"
                {...cell.getCellProps([
                  {
                    style: {
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      wordBreak: 'initial',
                      whiteSpace: 'nowrap',
                      cursor: 'pointer',
                    },
                    onClick: (e) => e.stopPropagation(),
                  },
                  getColumnProps(cell.column),
                  getCellProps(cell),
                ])}
                title={cell.value}
                align="left"
              >
                {renderCell(cell)}
              </TableCell>
            );
          })}
        </TableRow>
      );
    },
    [prepareRow, rows, page, toggleRowSelection, getCellProps]
  );

  const handleOnPageChange = useCallback(
    (_event, newPage) => {
      gotoPage(newPage);
    },
    [gotoPage]
  );

  const handleOnRowPerPageChange = useCallback(
    (event) => {
      setPageSize(Number(event?.target?.value));
      gotoPage(0);
    },
    [gotoPage, setPageSize]
  );

  function getFixedSizeListHeight() {
    if (enablePagination) {
      return pageSize < data.length
        ? pageSize * itemHeightForVirtualization
        : maxHeightForVirtualization;
    }
    return maxHeightForVirtualization;
  }

  const renderNoSearchMessage = () => {
    return rows?.length <= 0 ? (
      <Box
        display="flex"
        style={{ height: '100px' }}
        flexGrow={1}
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant="subtitle1">{noSearchResultMessage}</Typography>
      </Box>
    ) : null;
  };

  const renderContextMenu = () => {
    return rowLevelRightClickMenus?.length ? (
      <ContextMenus
        menus={rowLevelRightClickMenus}
        additionalData={{ test: '1' }}
        menuId={menuId}
      />
    ) : null;
  };

  const renderTablePagination = () => {
    return (
      enablePagination && (
        <TablePagination
          rowsPerPageOptions={[
            ...(rowsPerPageOptions
              ? rowsPerPageOptions.map((ele) => {
                  return { label: `${ele} rows`, value: ele };
                })
              : [5, 10, 20, 50].map((ele) => {
                  return { label: `${ele} rows`, value: ele };
                })),
            ...(isAllOptionDisabled
              ? []
              : [
                  {
                    label: 'All',
                    value: enableControlledPagination
                      ? allOptionValueSetter(rowsPerPageOptions, dataLength)
                      : allOptionValueSetter(rowsPerPageOptions, rows?.length),
                  },
                ]),
          ]}
          colSpan={columns?.length}
          count={enableControlledPagination ? dataLength : rows?.length}
          rowsPerPage={pageSize}
          page={pageIndex}
          slots={{
            select: {
              props: {
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              },
            },
          }}
          sx={{
            'button:focus-visible': {
              outline: (_theme) =>
                `2px solid ${_theme?.palette?.primary?.light}`, // Add your focus outline color
            },
            ' & .MuiTablePagination-select:focus-visible': {
              outline: (_theme) =>
                `2px solid ${_theme?.palette?.primary?.light}`, // Add focus outline for dropdown
            },

            '& .MuiPaginationItem-root:focus-visible': {
              outline: (_theme) =>
                `2px solid ${_theme?.palette?.primary?.light}`, // Add focus outline for dropdown
            },
          }}
          onPageChange={handleOnPageChange}
          onRowsPerPageChange={handleOnRowPerPageChange}
          labelRowsPerPage="Show:"
          {...(labelDisplayedRows ? { labelDisplayedRows } : {})}
          ActionsComponent={
            showPaginationAction ? ReactMuiTablePaginationActions : undefined
          }
        />
      )
    );
  };
  return (
    <Box width="100%">
      <ThemeProvider theme={newTheme}>
        <TableContainer>
          {data?.length && columns?.length ? (
            <Table {...getTableProps()} size="medium">
              <TableHead>
                {headerGroups?.map((headerGroup) => (
                  <TableRow
                    key={JSON.stringify(headerGroup)}
                    {...headerGroup.getHeaderGroupProps()}
                    role="row"
                    scope="row"
                  >
                    {headerGroup?.headers?.map((column) => {
                      const sortByToggleProps =
                        column?.id === 'selection'
                          ? {}
                          : column?.getSortByToggleProps();
                      return (
                        <TableCell
                          key={column?.heading}
                          align="center"
                          variant="head"
                          role="columnheader"
                          scope="col"
                          sx={{ textTransform: 'inherit' }}
                          {...column.getHeaderProps([
                            getColumnProps(),
                            getHeaderProps(),
                          ])}
                        >
                          {column.render('Header')}

                          {![
                            'selection',
                            'expander',
                            'pk',
                            'view',
                            'bot',
                            '',
                          ].includes(column.id) ? (
                            <Box
                              // @ts-ignore
                              {...(sortByToggleProps || {})}
                            >
                              {
                                // eslint-disable-next-line no-nested-ternary
                                column.isSorted ? (
                                  <TableSortLabel
                                    aria-sort={
                                      column?.isSortedDesc
                                        ? 'descending'
                                        : 'ascending'
                                    }
                                    aria-label={
                                      !column?.isSorted
                                        ? 'sort button'
                                        : column.isSortedDesc
                                          ? 'descending sort'
                                          : 'ascending sort'
                                    }
                                    component="button"
                                    active={column.isSorted}
                                    // react-table has a unsorted state which is not treated here
                                    direction={
                                      column.isSortedDesc ? 'desc' : 'asc'
                                    }
                                  />
                                ) : (
                                  sortedButton(column)
                                )
                              }
                            </Box>
                          ) : null}
                          <div
                            {...column.getResizerProps()}
                            className={`resizer ${
                              column.isResizing ? 'isResizing' : ''
                            }`}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody {...getTableBodyProps()}>
                {enableVirtualization ? (
                  <FixedSizeList
                    height={getFixedSizeListHeight()}
                    itemCount={(enablePagination ? page : rows).length}
                    itemSize={itemHeightForVirtualization}
                    width="100%"
                    style={{
                      overflowX: 'hidden',
                      overflowY: 'auto',
                    }}
                  >
                    {VirtualizedRowsRenderer}
                  </FixedSizeList>
                ) : (
                  (enablePagination ? page : rows)?.map((row, i) => {
                    prepareRow(row);
                    return (
                      <>
                        <TableRow
                          tabIndex={0}
                          role="row"
                          key={row?.id}
                          {...row.getRowProps([
                            getRowProps(row, theme, toggleAllRowsSelected),
                          ])}
                          aria-label={`${row?.id}`}
                          sx={{
                            '&:focus-visible': {
                              border: (_theme) =>
                                `2px solid ${_theme?.palette?.primary?.light}`,
                            },
                          }}
                        >
                          {!enableRowSelection
                            ? rowLevelOnHoverOptions({
                                className: `row-level-options-on-hover-${i}`,
                                containerSx:
                                  ROW_LEVEL_HOVER_OPTIONS_DEFAULT_STYLE,
                                rowIndex: i,
                                row,
                              })
                            : ''}
                          {row?.cells?.map((cell, index) => {
                            return (
                              <TableCell
                                role="cell"
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${cell?.value}-${index}`}
                                {...cell.getCellProps([
                                  {
                                    style: {
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      wordBreak: 'initial',
                                      whiteSpace: 'nowrap',
                                      cursor: 'pointer',
                                    },
                                    onClick: (e) => e.stopPropagation(),
                                  },
                                  getColumnProps(cell.column),
                                  getCellProps(cell),
                                ])}
                              >
                                {cell.render('Cell')}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        {row.isExpanded ? (
                          <TableRow>
                            <TableCell>
                              {renderRowSubComponent({ row })}
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </>
                    );
                  })
                )}
              </TableBody>
            </Table>
          ) : null}
          {renderNoSearchMessage()}
        </TableContainer>
        <Box width="100%" overflow="auto">
          {renderTablePagination()}
        </Box>
        {renderContextMenu()}
      </ThemeProvider>
    </Box>
  );
};

ReactMuiTableListView.defaultProps = {
  getHeaderProps: () => ({}),
  getColumnProps: () => ({}),
  // getRowProps: () => ({}),
  rowLevelOnHoverOptions: () => null,
  onRowSelect: () => ({}),
  externalCustomTheme: {},
  rowSelectionConfig: {
    enable: false,
    mode: 'checkbox',
    showDeleteOnSelected: false,
    type: 'multi-select',
  },
  onTableStateChange: () => ({}),
  enableRowSelection: false,
  toggle: false,
  rowLevelRightClickMenus: [],
  fetchData: () => {},
  enableControlledPagination: false,
  labelDisplayedRows: null,
  showPaginationAction: true,
  contextMenuCallback: () => {},
  hiddenColumns: [],
};

ReactMuiTableListView.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  getHeaderProps: PropTypes.func,
  getColumnProps: PropTypes.func,
  getRowProps: PropTypes.func.isRequired,
  rowLevelOnHoverOptions: PropTypes.func,
  onTableStateChange: PropTypes.func,
  onRowSelect: PropTypes.func,
  enableRowSelection: PropTypes.bool,
  toggle: PropTypes.bool,
  externalCustomTheme: PropTypes.oneOfType([PropTypes.object]),
  rowSelectionConfig: PropTypes.oneOfType([PropTypes.object]),
  rowLevelRightClickMenus: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  fetchData: PropTypes.func,
  enableControlledPagination: PropTypes.bool,
  dataLength: PropTypes.number.isRequired,
  labelDisplayedRows: PropTypes.func,
  showPaginationAction: PropTypes.bool,
  contextMenuCallback: PropTypes.func,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
};

export default ReactMuiTableListView;
