// @ts-nocheck
import React, { useCallback, useState } from 'react';
import {
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { Close, Photo } from '@mui/icons-material';
import PropTypes from 'prop-types';

const ModalRoot = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContainer = styled(Stack)(() => ({
  width: '100%',
  maxWidth: 500,
  backgroundColor: '#fff',
  borderRadius: 8,
}));

const ModalHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const ModalContent = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

/**
 *
 * @param {object} props
 * @param {import('react').ReactNode} props.label
 * @param {import('react').ChangeEventHandler<HTMLInputElement>} props.onFileChange
 * @param {string} props.accept
 */
const FileUploadButton = ({ label, onFileChange, accept, ...props }) => {
  return (
    <Button component="label" startIcon={<Photo />} {...props}>
      {label}
      <VisuallyHiddenInput
        type="file"
        accept={accept}
        onChange={onFileChange}
      />
    </Button>
  );
};
FileUploadButton.propTypes = {
  label: PropTypes.string.isRequired,
  onFileChange: PropTypes.func.isRequired,
  accept: PropTypes.string.isRequired,
};

/**
 * @param {object} props
 * @param {(base64: string) => void} props.onConfirm
 */
const UploadImageModal = ({ onConfirm, onClose, ...props }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const handleFileUpload = useCallback((e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    let dataURI;
    reader.onload = () => {
      dataURI = reader.result;
      setImagePreview(dataURI);
    };
    reader.readAsDataURL(file);
  }, []);

  const handleClose = useCallback(() => {
    setImagePreview(null);
    if (onClose) {
      onClose();
    }
  }, [setImagePreview, onClose]);

  const handleConfirm = useCallback(() => {
    onConfirm(imagePreview);
    handleClose();
  }, [onConfirm, imagePreview, handleClose]);

  return (
    <ModalRoot {...props}>
      <ModalContainer>
        <ModalHeader>
          <Typography>Upload Image</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </ModalHeader>
        <Divider />
        <ModalContent>
          <img
            src={imagePreview}
            alt={imagePreview ? 'Preview' : 'No image selected'}
          />
          <Stack direction="row" gap={2}>
            <FileUploadButton
              accept=".png,.jpg"
              label="Choose image"
              onFileChange={handleFileUpload}
              sx={{
                alignSelf: 'center',
              }}
              variant="outlined"
            />
            {imagePreview && (
              <Button variant="contained" onClick={handleConfirm}>
                Add Image
              </Button>
            )}
          </Stack>
        </ModalContent>
      </ModalContainer>
    </ModalRoot>
  );
};
UploadImageModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default UploadImageModal;
