// @ts-nocheck
import { MenuItem, MenuList, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

const MentionList = forwardRef(function MentionList(props, ref) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item });
    }
  };

  /*
  For upward navigation inside menu using keyboard arrow keys
  */
  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  /*
  For downward navigation inside menu using keyboard arrow keys
  */
  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  /*
  For selecting the item using keyboard enter key
  */
  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  /*
  keyboard event handlers
  */
  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  const getOnClickHandler = useCallback(
    (index) => {
      return () => selectItem(index);
    },
    [selectItem]
  );

  return (
    <Paper
      sx={{
        width: 350,
        maxWidth: '100%',
        maxHeight: 300,
        overflow: 'scroll',
        border: '1px solid rgba(0,0,0, 0.1)',
      }}
      elevation={0}
    >
      <MenuList>
        {props?.items?.length ? (
          props?.items?.map((item, index) => (
            <MenuItem
              className={index === selectedIndex ? 'is-selected' : ''}
              key={item}
              onClick={getOnClickHandler(index)}
              sx={{
                backgroundColor: (theme) =>
                  selectedIndex === index
                    ? 'rgba(0,0,0, 0.04)'
                    : theme.palette.other.white,
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              }}
            >
              {item}
            </MenuItem>
          ))
        ) : (
          <div className="item">No result</div>
        )}
      </MenuList>
    </Paper>
  );
});
MentionList.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array]).isRequired,
  command: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default MentionList;
